main {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    font-family: 'Montserrat', sans-serif;
}

main>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

main>div:nth-child(1) {
    width: 100%;
    height: 100%;
}

main>div>.logo {
    height: 40vmin;
}

main>div>div {
    width: 50%;
    font-size: calc(10px + 2vmin);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.75rem;
}

main>div>div>p {
    text-align: center;
    color: #3360f6;
    font-weight: 600;
}

main>div>div>a>img {
    height: 5vmin;
    cursor: pointer;
}

main>div>div>button {
    outline: none;
    border: none;
    margin-top: 2rem;
    height: 40px;
    width: 130px;
    letter-spacing: .5px;
    font-weight: bolder;
    line-height: 40px;
    font-size: 1.1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);
    background-color: #3360F6;
    border-radius: 8px;
    cursor: pointer;
    color: #ffffff;
}

main>div>div>button:hover {
    opacity: 0.9;
}

main>.footer {
    width: 100%;
    margin-bottom: 1rem;
}

main>.footer>.powered {
    display: flex;
    width: 100%;
    gap: .3rem;
    font-size: .88rem;
    justify-content: center;
    align-content: center;
    color: #6c757d;
}

main>.footer>.powered>img {
    max-width: 5rem;
    cursor: pointer;
}